<template>
  <StoreWidgets />
</template>

<script>
import StoreWidgets from "../store-widgets"

export default {
  name: "StoreWidgets2",
  components: {
    StoreWidgets
  }
}
</script>
